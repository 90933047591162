import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col, Form, Button } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    
    <Container className="my-4">
      <h1>Contact Us!</h1>
      <Row className="g-5">
        <Col lg={6}>
          <p>We would be thrilled to work with you on your new website. Feel free to use the contact form here or email or call us.</p>
          <p>Phone: +1 (415) 999-3378</p>
          <p>Email: <a href="mailto:haydn@nautilusdesigns.com">haydn@nautilusdesigns.com</a></p>
        </Col>
        <Col lg={6}>
          <Form 
            name="contact v1"
            method="post"
            action="/contact-success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit="submit"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact v1" />
            <p hidden>
            <label>
                Don’t fill this out: <input name="bot-field" />
            </label>
            </p>
            <Row>
              <Col md={6}>
                <Form.Group className="mt-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" name="first-name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-2">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name="last-name" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mt-2">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                  required
                  type="email" 
                  name="email" 
              />
              </Form.Group>

              <Form.Group className="mt-2">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control 
                      required
                      type="text" 
                      name="phone" 
                  />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>How can we help you?</Form.Label>
                <Form.Control 
                    as="textarea" 
                    name="how-can-we-help-you"
                    rows="3"
                  />
              </Form.Group>
              <Button type="submit" className="mt-2">Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
)

export default SecondPage
